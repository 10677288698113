<template>
  <div
    :class="this.grid"
    :style="{minWidth: '164px'}"
    class="k-field-wrapper"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      min-width="290px"
      offset-y
      transition="scale-transition"
    >
      <template #activator="{ on: {click: showMenu } }">
        <v-text-field
          ref="field"
          v-model="date"
          v-mask="masked(format)"
          v-bind="fieldProps"
          :hint="$t('hints.acceptedDateFormat', { format })"
          :label="translatedLabel"
          :placeholder="format"
          :rules="[validateDate, ...computedRules]"
          append-icon="$date"
          @click:append="showMenu"
        />
      </template>
      <v-date-picker
        ref="picker"
        v-model="datePickerDate"
        :max="parseToDatePicker(maxDate)"
        :min="parseToDatePicker(minDate)"
        :type="type"
        no-title
        scrollable
      >
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="menu = false"
        >
          {{ $t('actions.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="updateDateFieldFromPicker"
        >
          {{ $t('actions.continue') }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';
import dayjs from '@/plugins/dayjs.js';

export default {
  name: 'KDateField',
  mixins: [Field],
  props: {
    value: {
      type: String,
    },
    yearFirst: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY',
    },
    minDate: {
      type: [String, dayjs],
    },
    maxDate: {
      type: [String, dayjs],
    },
    type: {
      type: String,
      validator: (val) => ['month', '', null].indexOf(val) >= 0,
    },
  },
  data: () => ({
    menu: false,
    date: '',
    datePickerDate: '',
    datePickerFormat: 'YYYY-MM-DD',
  }),
  watch: {
    async date(value) {
      if (!!this.safeDateValue(value) || !value) {
        await this.$emit('input', value && this.parseToDatePicker(this.safeDateValue(value)));
        this.$refs.field.validate();
      }
    },
    value: {
      handler(value) {
        if (!!this.safeDateValue(value) || !value) {
          this.date = value && this.parseToInputDate(this.safeDateValue(value));
          this.datePickerDate = value && this.parseToDatePicker(this.safeDateValue(value));
        }
      },
      immediate: true,
    },
    menu(value) {
      if (!this.yearFirst) return;
      !!value && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  methods: {
    validateDate(date) {
      if (!date) return true;
      return dayjs(date, this.format).isValid() || this.$refs?.field?.hint || '';
    },
    parseToInputDate(date) {
      return date && dayjs(date).format(this.format);
    },
    parseToDatePicker(date) {
      return date && dayjs(date).format(this.datePickerFormat);
    },
    masked(format) {
      return format.replace(/\w/g, 'X');
    },
    safeDateValue(value) {
      const parsedDate = dayjs(value, [this.format, this.datePickerFormat]);
      return parsedDate.isValid() && parsedDate;
    },
    updateDateFieldFromPicker() {
      this.date = this.parseToInputDate(this.datePickerDate);
      this.menu = false;
    },
  },
};
</script>
