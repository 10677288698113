<template>
  <KPaginatedAutocomplete
    v-bind="$attrs"
    :paginator="autocomplete"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from './../api/puzzlegroup.js';

export default {
  name: 'PuzzleGroupAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete,
  },
};
</script>
