import Model from './model';
import dayjs from '@/plugins/dayjs.js';

export default class Puzzle extends Model {
  id = 0;
  name = '';
  brand = '';
  serial = '';
  shapeId = 1;
  pieces =  '';
  width = '';
  height = '';
  startDate = dayjs();
  endDate = dayjs();
  difficultyId = 2;
  statusId = 5;
  qualityId = 2;
  remark = null;
  photo = null;
  isComplete = false;
  missingPieces = [];
  numberOfMissingPieces = 0;
  puzzleGroup = null;

  mapForRequest() {
    return {
      name: this.name,
      brand: this.brand,
      serial: this.serial,
      shapeId: this.shapeId,
      pieces: this.pieces,
      width: parseInt(this.width),
      height: parseInt(this.height),
      startDate: this.startDate,
      endDate: this.endDate,
      difficultyId: this.difficultyId,
      qualityId: this.qualityId,
      puzzleGroupId: this.puzzleGroup.id,
      statusId: this.statusId,
      remark: this.remark,
      photo: this.photo,
      isComplete: this.isComplete,
      missingPieces: this.missingPieces,
    };
  }
}
