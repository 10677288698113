import Model from '@/application/models/model.js';

export default class MissingPiece extends Model {
  id = null;
  row = null;
  column = null;

  mapForRequest() {
    return {
      id: this.id || null,
      row: this.row,
      column: this.column,
    };
  }
}
