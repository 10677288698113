<template>
  <k-field-group language-prefix="puzzle.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      :is-update-form="isUpdateForm"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <v-col>
            <PuzzleGroupAutocomplete
              v-model="values.puzzleGroup"
              field="puzzleGroup"
              grid="col-11"
              :request="puzzleGroupsAutocompleteRequest"
              :select-if-one="true"
              required
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KTextField
              v-model="values.name"
              field="name"
              grid="col-11"
              required
            />
          </v-col>
          <v-col class="col-12" />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.brand"
            field="brand"
            grid="col-11"
            required
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.serial"
            field="serial"
            class="col-11"
          />
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KTextField
              v-model="values.pieces"
              field="pieces"
              type="number"
              class="col-10"
              required
            />
          </v-col>
          <v-col>
            <KSelect
              v-model="values.shapeId"
              :items="shapeOptions"
              class="col-10"
              field="shape"
              item-text="text"
              item-value="value"
              required
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KFigureField
              v-model="values.width"
              class="col-10"
              field="width"
              type="number"
              suffix="cm"
              required
            />
          </v-col>
          <v-col>
            <KFigureField
              v-model="values.height"
              class="col-10"
              field="height"
              type="number"
              suffix="cm"
              required
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KSelect
              v-model="values.qualityId"
              :items="qualityOptions"
              class="col-10"
              field="quality"
              item-text="text"
              item-value="value"
              required
            />
          </v-col>
          <v-col>
            <KSelect
              v-model="values.difficultyId"
              :items="difficultyOptions"
              class="col-10"
              field="difficulty"
              item-text="text"
              item-value="value"
              required
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KDateField
              v-model="values.startDate"
              class="col-10"
              field="startDate"
              hide-details
              required
            />
          </v-col>
          <v-col>
            <KDateField
              v-model="values.endDate"
              class="col-10"
              field="endDate"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <KSelect
              v-model="values.statusId"
              :items="statusOptions"
              class="col-11"
              field="status"
              item-text="text"
              item-value="value"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <KTextarea
              v-model="values.remark"
              field="remark"
              class="col-11"
            />
          </v-col>
        </v-row>
      </template>
      <template #panel.1>
        <v-row no-gutters>
          <v-col>
            <KFileField
              v-model="values.photo"
              accept="image/*"
              field="photo"
            />
          </v-col>
        </v-row>
      </template>
      <template #panel.2>
        <v-col>
          <MissingPiecesTable :missing-pieces-to-add="values" />
        </v-col>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { shapes } from '@/application/enums/shapes.ts';
import { qualities } from '@/application/enums/qualities.ts';
import { difficulties } from '@/application/enums/difficulties.ts';
import { statuses } from '@/application/enums/statuses.ts';
import { getTranslatedEnumOptions } from '@/application/util/enum.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import PuzzleGroupAutocomplete from '@/modules/puzzlegroup/components/PuzzleGroupAutocomplete.vue';
import MissingPiecesTable from '@/modules/puzzle/components/MissingPiecesTable.vue';
import { puzzleGroupAutocomplete } from '@/modules/puzzlegroup/api/puzzlegroup.js';

export default {
  name: 'PuzzleForm',
  components: {
    PuzzleGroupAutocomplete,
    KTextarea,
    KSelect,
    KFieldGroup,
    KTextField,
    KFigureField,
    KFileField,
    KDateField,
    KFormDialog,
    MissingPiecesTable,
  },
  props: {
    values: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('puzzle.tabs.default') },
        { name: this.$t('puzzle.tabs.photos') },
        { name: this.$t('puzzle.tabs.pieces') },
      ];
    },
    shapeOptions() {
      return getTranslatedEnumOptions(shapes, 'puzzle.shapes');
    },
    qualityOptions() {
      return getTranslatedEnumOptions(qualities, 'puzzle.qualities');
    },
    difficultyOptions() {
      return getTranslatedEnumOptions(difficulties, 'puzzle.difficulties');
    },
    statusOptions() {
      return getTranslatedEnumOptions(statuses, 'puzzle.statuses');
    },
  },
  watch: {},
  methods: {
    puzzleGroupsAutocompleteRequest() {
      return puzzleGroupAutocomplete(...arguments);
    },
  },
};
</script>
