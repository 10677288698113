<template>
  <!-- @TODO: Issue with v-menu in v-dialog: https://github.com/vuetifyjs/vuetify/issues/9283 -->
  <v-dialog
    v-if="delayedValue"
    :value="value"
    fullscreen
    no-click-animation
    persistent
    transition="dialog-bottom-transition"
    @input="$emit('input', $event)"
  >
    <v-card
      :class="!hasDefaultSlot && panelSlots.length ? 'form-dialog--panels' : 'form-dialog--single-form'"
      data-cy="form-dialog"
    >
      <v-card-title>
        <VSpacer />
        <v-btn
          color="primary"
          outlined
          tile
          @click="handleCancel"
        >
          {{ $t('actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="addAnother && !isUpdateForm"
          :loading="isRequestLoading"
          class="ml-3"
          color="primary"
          outlined
          tile
          @click="handleSaveAndAddAnother()"
        >
          {{ $t('actions.saveAnother') }}
        </v-btn>
        <v-btn
          :loading="isRequestLoading"
          class="ml-3"
          color="primary"
          depressed
          tile
          @click="handleSaveAndClose()"
        >
          {{ $t('actions.save') }}
        </v-btn>
      </v-card-title>
      <v-row no-gutters>
        <v-col
          :lg="hideSvg ? '12' : '8'"
          :xl="hideSvg ? '12' : '6'"
        >
          <v-card-text class="pr-12">
            <h2 class="text-h1 primary--text text--darken-4 mb-6">
              {{ $t(`actions.${isUpdateForm ? 'updateResource' : 'createResource'}`, { resource: title }) }}
            </h2>
            <v-expand-transition>
              <div v-if="hasServerError">
                <v-alert
                  outlined
                  type="error"
                >
                  {{ $t('validation.validationError') }}
                </v-alert>
              </div>
            </v-expand-transition>
            <v-tabs
              v-if="!hasDefaultSlot && panelSlots.length"
              v-model="activeTab"
              color="primary darken-4"
              vertical
            >
              <v-tab
                v-for="(slot, index) in panelSlots"
                :key="index"
                :class="{'error--text' : panelValidation[index] === false}"
                v-bind="panels[index]"
                :disabled="panels[index].disabled"
                class="justify-start"
              >
                {{ panels[index].name || 'tab' }}
              </v-tab>
              <v-tab-item
                v-for="(slot, index) in panelSlots"
                :key="index"
                class="pl-6"
                eager
              >
                <v-form
                  ref="form"
                  v-model="panelValidation[index]"
                  lazy-validation
                >
                  <slot :name="slot" />
                </v-form>
              </v-tab-item>
            </v-tabs>
            <v-form
              v-else
              ref="form"
              v-model="panelValidation[0]"
              lazy-validation
            >
              <slot />
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import UpdateSvg from '@/images/FormSvg.vue';

export default {
  name: 'KFormDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    panels: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    request: {
      type: Function,
    },
    addAnother: {
      type: Boolean,
      default: false,
    },
    isUpdateForm: {
      default: false,
      type: Boolean,
    },
    hideSvg: {
      default: false,
      type: Boolean,
    },
    // can be used to override server validation
    // for example when actionables fail
    clientIsValid: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      delayedValue: false,
      panelValidation: [],
      isRequestLoading: false,
      hasServerError: false,
      activeTab: 0,
    };
  },
  computed: {
    panelSlots() {
      return Object.keys(this.$slots)
          .filter((slot) => slot.substr(0, 6) === 'panel.');
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasValidationErrors() {
      return this.panelValidation.includes(false);
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (!oldValue && newValue) {
        //Dialog opened
        this.$nextTick(() => {
          this.addTabEventListener();
        });
        this.delayedValue = true;
        return;
      }
      setTimeout(() => this.delayedValue = false, 500);

    },
  },
  methods: {
    addTabEventListener() {
      for (let i = 0; i < this.$refs.form.length; i++) {
        const inputs = this.$refs.form[i].$el.querySelectorAll('input:not([type=hidden])');
        if (!inputs.length) {
          return;
        }
        inputs[inputs.length - 1].addEventListener('keydown', (e) => {
          if (e.keyCode === 9) {
            if (i === this.$refs.form.length - 1) {
              return;
            }
            this.activeTab++;
          }
        });
      }
    },
    handleCancel() {
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('input', false);
      this.reset();
    },
    validate() {
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.validate();
        });
      } else {
        this.$refs.form.validate();
      }
    },
    reset() {
      this.activeTab = 0;
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.reset();
        });
      } else {
        this.$refs.form.reset();
      }
      this.hasServerError = false;
    },
    resetValidation() {
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.resetValidation();
        });
      } else {
        this.$refs.form.resetValidation();
      }
      this.hasServerError = false;
    },
    async handleSaveAndClose() {
      await this.handleSave(true);
    },
    async handleSaveAndAddAnother() {
      await this.handleSave(false);
    },
    async handleSave(closeOnSuccess) {
      this.validate();
      if (this.hasValidationErrors) return;
      if (!this.clientIsValid) return;
      this.isRequestLoading = true;
      try {
        await this.request();
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t(`actions.${this.isUpdateForm ? 'resourceUpdated' : 'resourceCreated'}`, { resource: this.title }),
        });
        closeOnSuccess && this.closeDialog();
        this.$emit('change');
      } catch (error) {
        if (error.response && error.response.status) {
          this.hasServerError = true;
          this.validate();
          return;
        }
        throw error;
      } finally {
        this.isRequestLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card__text {
  border-right: 1px solid #c4c4c4;
}

.form-dialog--panels h2 {
  margin-left: 222px
}

.v-tabs ::v-deep > .v-item-group {
  width: 200px;
}
</style>
