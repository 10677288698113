import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('puzzle', page, perPage, search, sortBy, descending, params);
}

/**
 * @param puzzleId {number}
 */
function show(puzzleId) {
  return get(`puzzle/${puzzleId}`);
}

/**
 * @param puzzle {Puzzle}
 */
function create(puzzle) {
  return post('puzzle', puzzle);
}

/**
 * @param puzzle {Puzzle}
 */
function update(puzzle) {
  return put(`puzzle/${puzzle.id}`, puzzle);
}

/**
 * @param puzzleId {number}
 */
function remove(puzzleId) {
  return destroy(`puzzle/${puzzleId}`);
}


export {
  index,
  show,
  create,
  update,
  remove,
};
