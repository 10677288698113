<template>
  <div class="pa-6">
    <v-row no-gutters>
      <v-col>
        <slot name="header" />
      </v-col>
      <v-col cols="auto">
        <v-row
          align="center"
          no-gutters
        >
          <slot name="flow-actions" />
        </v-row>
      </v-col>
    </v-row>
    <slot #default />
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
};
</script>
