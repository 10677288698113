<template>
  <div
    :class="this.grid"
    class="k-field-wrapper"
  >
    <v-select
      :label="translatedLabel"
      :rules="computedRules"
      v-bind="fieldProps"
      v-on="$listeners"
    >
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>

      <!-- pass through normal slots -->
      <template
        v-for="(_, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </v-select>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KSelect',
  mixins: [Field],
  inheritAttrs: false,
};
</script>
