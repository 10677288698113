





























import Field from '@/components/crud/fields/FieldMixin.vue';
import Vue from 'vue';

interface ComponentData {
  preview: string,
  previewType: string
}

interface fileObject {
  type: string,
  preview: string,
  url: string
}

export default Vue.extend({
  name: 'KFileField',
  mixins: [Field],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Object, File],
    },
  },
  data: (): ComponentData => ({
    preview: '',
    previewType: '',
  }),
  computed: {
    icon(): string {
      if (!this.previewType) {
        return '$upload';
      }
      return this.getIconByType(this.previewType);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.fetchPreview(value);
      },
    },
  },
  methods: {
    fetchPreview(file: File | fileObject) {
      if (!file || !file.type) {
        this.preview = '';
        this.previewType = '';
        return;
      }
      if (file.type.match('image.*')) {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.preview = typeof reader.result === 'string' ? reader.result : '';
            this.previewType = '';
          }, false);
          reader.readAsDataURL(file);
          return;
        }
        this.preview = file.url;
        return;
      }
      this.preview = '';
      this.previewType = file.type;
    },
    getIconByType(type: string): string {
      if (type.match('video/*')) {
        return '$fileVideo';
      } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/msword') {
        return '$fileWord';
      } else if (type === 'application/pdf') {
        return '$filePdf';
      }
      return '$fileUnknown';
    },
  },
});
