<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="missingPiecesToAdd.missingPieces"
          :item-class="setRowConceptClass"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="red"
              icon
              @click.stop="handleDeleteClick(item)"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          ref="newMissingPieceForm"
          v-model="newMissingPieceValid"
        >
          <v-row align="center">
            <v-col>
              <KTextField
                v-model="newMissingPiece.row"
                field="row"
                required
              />
            </v-col>
            <v-col>
              <KTextField
                v-model="newMissingPiece.column"
                field="column"
                required
              />
            </v-col>
            <v-col>
              <v-btn
                :disabled="!newMissingPieceValid"
                @click="handleAddMissingPieceClick"
              >
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MissingPiece from '@/application/models/MissingPiece.js';
import KTextField from '@/components/crud/fields/KTextField.vue';

export default {
  name: 'MissingPiecesTable',
  components: {
    KTextField,
  },
  props: {
    missingPiecesToAdd: {
      type: Object,
      required: true,
    },
    conceptMissingPieces: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      missingPieces: [],
      newMissingPiece: new MissingPiece(),
      newMissingPieceValid: false,
      headers: [
        {
          text: this.$t('puzzle.missing.fields.row'),
          value: 'row',
          align: 'start',
        },
        {
          text: this.$t('puzzle.missing.fields.column'),
          value: 'column',
          align: 'start',
        },
        {
          text:  this.$t('puzzle.missing.delete'),
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
    };
  },
  watch: {
  },
  methods: {
    handleAddMissingPieceClick() {
      this.missingPiecesToAdd.missingPieces.push(this.newMissingPiece);
      this.newMissingPiece = new MissingPiece();
      this.$refs.newMissingPieceForm.reset();
    },
    handleDeleteClick(item) {
      const idx = this.missingPiecesToAdd.missingPieces.indexOf(item);
      if (idx > -1) {
        this.missingPiecesToAdd.missingPieces.splice(idx, 1);
      }
    },
    setRowConceptClass() {
      return 'text--disabled';
    },
  },
};
</script>

<style scoped>
.text-align-right::v-deep input {
  text-align: end
}
</style>
